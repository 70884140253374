
body {
	margin: 0;
	padding: 0;
	background: #151515 url(../images/background.png) 0 0;
	color: #eaeaea;
	font-size: 16px;
	line-height: 1.5;
	font-family: $font;
}

.wrap {
	width: 90%;
	max-width: 600px;
	margin: 0 auto;
}

.site-header {
	background: rgba(0, 0, 0, 0.1);
	width: 100%;
	border-bottom: 1px dashed $green;
	padding: 20px 0;
	margin: 0 0 40px 0;


	h1 {
		font-size: 30px;
		line-height: 1.5;
		margin: 0 0 0 -40px;
		font-weight: bold;
		font-family: $font;
		color: $green;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1),
		             0 0 5px rgba(181, 232, 83, 0.1),
		             0 0 10px rgba(181, 232, 83, 0.1);
		letter-spacing: -1px;
		-webkit-font-smoothing: antialiased;

		&:before {
			content: "./ ";
			font-size: 24px;
		}
	}

	h2 {
		font-size: 18px;
		font-weight: 300;
		color: #666;
	}
}

.site-footer {

	$color: #666;
	$shadow: 0 0 3px rgba(104, 182, 255, 0.5);

	text-align: center;
	color: #666;
	text-shadow: $shadow;

	a {
		text-decoration: none;

		&:hover svg {
			fill: $blue;
		}
	}

	svg {
		height: 35px;
		font-size: 35px;
		width: 35px;
		text-align: center;
		margin-left: 7px;
		fill: $color;
		filter: drop-shadow($shadow);
	}
}
